/*
 * @Description:
 * @Autor: elvin
 * @Date: 2021-04-03 14:57:31
 * @LastEditors: elvin
 * @LastEditTime: 2022-07-03 20:58:01
 */
import {
    AreaChartOutlined,
    FileDoneOutlined,
    BarsOutlined,
    TeamOutlined,
    ProjectOutlined,
    AccountBookOutlined,
    BarChartOutlined,
    AppstoreOutlined,
    RedEnvelopeOutlined,
    SyncOutlined,
} from '@ant-design/icons';
import { getLocal } from '../../utils';

const menuConfig = [

    {
        key: '/dashboard',
        name: '数据总览',
        icon: AreaChartOutlined,
        children: [
            {
                key: '/dashboard/analysis',
                name: '数据分析',
                children: false
            },
          
        ]
    },

    {
        key: '/usermanage',
        name: '用户管理',
        icon: BarsOutlined,
        children: [
            {
                key: '/usermanage/userlist',
                name: '用户列表',
                children: false
            },
            {
                key: '/usermanage/invitelist',
                name: '邀请列表',
                children: false
            },
            {
                key: '/usermanage/withdrawlist',
                name: '提现列表',
                children: false
            },
            {
                key: '/usermanage/devicelist',
                name: '软件列表',
                children: false
            },
        ]
    },
    {
        key: '/advertisement',
        name: '广告管理',
        icon: ProjectOutlined,
        children: [
            {
                key: '/advertisement/adresource',
                name: '广告资源库',
                children: false
            },
            {
                key: '/advertisement/adlist',
                name: '广告列表',
                children: false
            },
            {
                key: '/advertisement/cpatasklist',
                name: 'CPA任务列表',
                children: false
            },
            {
                key: '/advertisement/cpltasklist',
                name: 'CPL任务列表',
                children: false
            },
            {
                key: '/advertisement/dylist',
                name: '多游CPL列表',
                children: false
            },
        ]
    },
    {
        key: '/activity',
        name: '活动管理',
        icon: ProjectOutlined,
        children: [
            {
                key: '/activity/list',
                name: '游戏周榜',
                children: false
            },
            // {
            //     key: '/activity/other',
            //     name: '其他活动',
            //     children: false
            // },
            
        ]
    },
    {
        key: '/finance',
        name: '客户&财务',
        icon: AccountBookOutlined,
        children: [
            {
                key: '/finance/customer',
                name: '客户列表',
                children: false
            },
            // {
            //     key: '/finance/bills',
            //     name: '对账单',
            //     children: false
            // },
            {
                key: '/finance/statement',
                name: '对账单',
                children: false
            },
            {
                key: '/finance/income',
                name: '到款记录',
                children: false
            }
        ]
    },
    {
        key: '/statistics',
        name: '数据统计',
        icon: BarChartOutlined,
        children: [
            {
                key: '/statistics/bills',
                name: '财务报表',
                children: false
            },
            {
                key: '/statistics/addverreport',
                name: '广告报表',
                children: false
            },
            {
                key: '/statistics/cpl-report',
                name: 'CPL报表',
                children: false
            },
            {
                key: '/statistics/cpa-report',
                name: 'CPA报表',
                children: false
            },
        ]
    },
    {
        key: '/channel',
        name: '渠道管理',
        icon: AppstoreOutlined,
        children: [
            {
                key: '/channel/channellist',
                name: '渠道管理',
                children: false
            },
            {
                key: '/channel/channelreport',
                name: '渠道报表',
                children: false
            },
            {
                key: '/channel/talentlist',
                name: '达人数据',
                children: false
            },
            // {
            //     key: '/channel/talentsettlement',
            //     name: '达人结算',
            //     children: false
            // },
            // {
            //     key: '/channel/config',
            //     name: '达人配置',
            //     children: false
            // }
        ]
    },
    // {
    //     key: '/task',
    //     name: '任务管理',
    //     icon: FileDoneOutlined,
    //     children: [
    //         {
    //             key: '/task/tasklist',
    //             name: '每日任务',
    //             icon: FileDoneOutlined,
    //             children: false
    //         },
    //         {
    //             key: '/task/signinlist',
    //             name: '签到任务',
    //             icon: FileDoneOutlined,
    //             children: false
    //         },
            
    //     ]
    // },

    {
        key: '/service',
        name: '客服系统',
        icon: RedEnvelopeOutlined,
        children: [
            {
                key: '/service/list',
                name: '问题配置',
                children: false
            },
        ]
    },

    // {
    //     key: '/tigerfood',
    //     name: '虎粮管理',
    //     icon: RedEnvelopeOutlined,
    //     children: [
    //         {
    //             key: '/tigerfood/List',
    //             name: '虎粮明细',
    //             children: false
    //         },
    //         {
    //             key: '/tigerfood/drawrewad',
    //             name: '抽奖明细',
    //             children: false
    //         },
            
    //     ]
    // },
    // {
    //     key: '/query',
    //     name: '数据同步',
    //     icon: SyncOutlined,
    //     children: [
    //         {
    //             key: '/sync/query',
    //             name: '游戏数据查询',
    //             children: false
    //         },
           
            
    //     ]
    // },
]

const userRoleCode = getLocal("role");

if(["admin"].includes(userRoleCode)){
    menuConfig.push(
        {
            key: '/account/list',
            name: '员工管理',
            icon: TeamOutlined,
            children: false
        }
    )
}

export default menuConfig;